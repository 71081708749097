import type { BreadcrumbState, BreadcrumbStoreDefinition } from "@/Types/stores";
import { defineStore } from "pinia";

export const useBreadcrumbStore: BreadcrumbStoreDefinition = defineStore({
    id: 'useBreadcrumbStore',
    state: (): BreadcrumbState => ({
        title: '',
        subtitle: '',
    }),
    actions:
    {
        set(title: string, subtitle: string): void
        {
            this.title = title;
            this.subtitle = subtitle;
        },
        reset(): void
        {
            this.$reset();
        }
    }
});