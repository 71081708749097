<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";
// Layout Stores
import { usePersonalizationThemeStore } from '@/Layouts/Stores/usePersonalizationStore';
// Components
import TheDropdown from '@/Components/Dropdown/TheDropdown.vue';
import TheDropdownMenu from '@/Components/Dropdown/TheDropdownMenu.vue';
import TheDropdownMenuButton from '@/Components/Dropdown/TheDropdownMenuButton.vue';
// Icon Components
import PaletteIcon from '@/Components/Icons/Bootstrap/PaletteIcon.vue';
import CPUIcon from '@/Components/Icons/Bootstrap/CPUIcon.vue';
import MoonStarsIcon from '@/Components/Icons/Bootstrap/MoonStarsIcon.vue';
import SunIcon from '@/Components/Icons/Bootstrap/SunIcon.vue';
//
import type { PersonalizationThemeStore } from '@/Types/stores';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize usePersonalizationThemeStore.
 * 
 * @type { PersonsalizationThemeStore }
 */
const personalizationThemeStore: PersonalizationThemeStore = usePersonalizationThemeStore();

/**
 * PART: Get color scheme from theme store.
 * 
 * @type { ComputedRef<string> }
 */
const getColorScheme: ComputedRef<string> = computed(() => personalizationThemeStore.colorScheme);

/**
 * PART: Set default color scheme.
 * 
 * @returns { void }
 */
const setDefaultColorScheme = (): void =>
{
    personalizationThemeStore.setDefaultColorScheme();
};

/**
 * PART: Set dark color scheme.
 * 
 * @returns { void }
 */
const setDarkColorScheme = (): void =>
{
    personalizationThemeStore.setDarkColorScheme();
};

/**
 * PART: Set light color scheme.
 * 
 * @returns { void }
 */
const setLightColorScheme = (): void =>
{
    personalizationThemeStore.setLightColorScheme();
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="gd-header-mobile__nav--item">
        <TheDropdown 
            dropdown-id="header-personalization"
            button-title="Personalization"
            button-class="gd-header-mobile__nav--button"
            align="right"
        >
            <template #button>
                <PaletteIcon/>
            </template>
            <template #content>
                <TheDropdownMenu>
                    <TheDropdownMenuButton title="Default" @handle-click-event="setDefaultColorScheme" :active="getColorScheme === 'default'">
                        <template #icon="iconProps">
                            <CPUIcon :variant="iconProps.variant" />
                        </template>
                        <template #text>
                            Default
                        </template>
                    </TheDropdownMenuButton>
                    <TheDropdownMenuButton title="Dark" @handle-click-event="setDarkColorScheme" :active="getColorScheme === 'dark'">
                        <template #icon="iconProps">
                            <MoonStarsIcon :variant="iconProps.variant" />
                        </template>
                        <template #text>
                            Dark
                        </template>
                    </TheDropdownMenuButton>
                    <TheDropdownMenuButton title="Light" @handle-click-event="setLightColorScheme" :active="getColorScheme === 'light'">
                        <template #icon="iconProps">
                            <SunIcon :variant="iconProps.variant" />
                        </template>
                        <template #text>
                            Light
                        </template>
                    </TheDropdownMenuButton>
                </TheDropdownMenu>
            </template>
        </TheDropdown>
    </li>
</template>