<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    location: 
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Page menu prefix.
 * 
 * @type { string }
 */
const PAGE_MENU_PREFIX: string = 'page-menu__';

/**
 * PART: Location allowed.
 * 
 * @type { ReadonlyArray<string> }
 */
const locationAllowed: ReadonlyArray<string> = ['float', 'aside'];

/**
 * PART: Set the class location of the class on the page menu.
 * 
 * @type { ComputedRef<string> }
 */
const setLocationClass: ComputedRef<string> = computed(() => {
    const { location } = props;
    
    if(!locationAllowed.includes(location))
    {
        throw new Error('Location page menu is not valid');
    }

    return PAGE_MENU_PREFIX.concat(location);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <div class="page-menu" v-bind:class="[setLocationClass]">
        <div class="page-menu__content">
            <h6 class="page-menu__title">
                <slot name="title"></slot>
            </h6>
        </div>
        <div class="page-menu__content">
            <ul class="page-menu__list">
                <slot name="menu"></slot>
            </ul>
        </div>
    </div>
</template>