<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch } from "vue";
// Layout Stores
import { useHeaderMobileDropdownStore } from '@/Layouts/Stores/useHeaderMobileStore';
// Component
import TheHamburger from '@/Components/Animation/TheHamburger.vue';
import type { HeaderMobileDropdownStore } from '@/Types/stores';
import { storeToRefs } from 'pinia';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * Initalize useHeaderMobileDropdownStore.
 * 
 * @type { HeaderMobileDropdownStore }
 */
const store: HeaderMobileDropdownStore = useHeaderMobileDropdownStore();
const { animation, active } = storeToRefs(store);


/**
 * PART: Handle toggler dropdown.
 * 
 * @returns { void }
 */
const handleTogglerDropdown = (): void =>
{
    if(animation.value === true) {
        return;
    }

    active.value ? store.hide() : store.show();
};

watch(active, (bool: boolean) => {
    if(bool === true) {
        document.body.setAttribute('style', 'overflow:hidden');
    } else {
        document.body.removeAttribute('style');
    }
}, { immediate: true });

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="gd-header-mobile__nav--item">
        <TheHamburger 
            @handle-click-event="handleTogglerDropdown"
            :active="active"
            :variant="2"
            class="gd-header-mobile__nav--hamburger"
            ariaLabel="Mobile Navigation"
            ariaControls="gd-header-mobile__container"
        />
    </li>
</template>