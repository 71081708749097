<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Layout Stores
import { useLayoutModeStore } from "@/Layouts/Stores/usePersonalizationStore";
// Layout Components
import FloatPersonalizationComponent from "@/Layouts/Components/Float/FloatPersonalizationComponent.vue";
import FloatPersonalizationButton from "@/Layouts/Components/Float/FloatPersonalizationButton.vue";
// Icons Components
import LayoutSidebarInsetIcon from '@/Components/Icons/Bootstrap/LayoutSidebarInsetIcon.vue';
import LayoutSidebarIcon from "@/Components/Icons/Bootstrap/LayoutSidebarIcon.vue";
import SquareIcon from "@/Components/Icons/Bootstrap/SquareIcon.vue";
import type { LayoutModeStore } from "@/Types/stores";
import { storeToRefs } from "pinia";

/*
** !SECTION
|----------------------------------------
** SECTION: Import
*/

/**
 * PART: Initialize useLayoutModeStore.
 * 
 * @type { LayoutModeStore }
 */
const store: LayoutModeStore = useLayoutModeStore();
const { mode } = storeToRefs(store);

/**
 * PART: Set default layout mode.
 * 
 * @returns { void }
 */
const setDefault = (): void =>
{
    store.setDefault();
};

/**
 * PART: Set minimize layout mode.
 * 
 * @returns { void }
 */
const setMinimize = (): void =>
{
    store.setMinimize();
};

/**
 * PART: Set maximize layout mode.
 * 
 * @returns { void }
 */
const setMaximize = (): void =>
{
    store.setMaximize();
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <FloatPersonalizationComponent>
        <template #title>
            Layout
        </template>
        <template #default>
            <FloatPersonalizationButton 
                @handle-click-event="setDefault"
                :mounted="true"
                :active="mode === 'default'"
                title="Default" 
            >
                <template #back-icon>
                    <LayoutSidebarInsetIcon />
                </template>
                <template #header-title>
                    #1
                </template>
                <template #icon>
                    <LayoutSidebarInsetIcon />
                </template>
                <template #label>
                    Default
                </template>
            </FloatPersonalizationButton>   
            <FloatPersonalizationButton 
                @handle-click-event="setMinimize"
                :mounted="true"
                :active="mode === 'minimize'"
                title="Minimize" 
            >
                <template #back-icon>
                    <LayoutSidebarIcon />
                </template>
                <template #header-title>
                    #2
                </template>
                <template #icon>
                    <LayoutSidebarIcon />
                </template>
                <template #label>
                    Minimize
                </template>
            </FloatPersonalizationButton>   
            <FloatPersonalizationButton 
                @handle-click-event="setMaximize"
                :active="mode === 'maximize'"
                :mounted="true"
                title="Maximize" 
            >
                <template #back-icon>
                    <SquareIcon />
                </template>
                <template #header-title>
                    #3
                </template>
                <template #icon>
                    <SquareIcon />
                </template>
                <template #label>
                    Maximize
                </template>
            </FloatPersonalizationButton>   
        </template>
    </FloatPersonalizationComponent>
</template>