import type { ErrorExceptionState, ErrorExceptionStoreDefinition } from "@/Types/stores";
import { defineStore } from "pinia";

export const useErrorExceptionStore: ErrorExceptionStoreDefinition = defineStore({
    id: 'useErrorExceptionStore',
    state: (): ErrorExceptionState => ({
        error: false,
        status: 0,
        statusText: '',
        messages: {},
    }),
    actions:
    {
        abort(status: number, statusText: string, messages: Record<any, any> = {}): void
        {
            this.status = status;
            this.statusText = statusText;
            this.messages = messages;
            this.error = true;
        },
        reset(): void
        {
            this.status = 0;
            this.statusText = '';
            this.messages = {};
            this.error = false;
        }
    },
})