type ColorThemes =  'core'| 'brand'| 'primary'| 'secondary'| 'danger'| 'warning'| 'info'| 'success'| 'facebook'| 'instagram'| 'whatsapp'| 'linkedin'| 'youtube'| 'github'| 'twitter'| 'telegram';

export const colorThemes: ReadonlyArray<ColorThemes|string> = [
    'core',
    'brand',
    'primary',
    'secondary',
    'danger',
    'warning',
    'info',
    'success',
    'facebook',
    'instagram',
    'whatsapp',
    'linkedin',
    'youtube',
    'github',
    'twitter',
    'telegram',
];