import { defineStore } from "pinia";
import { useLayoutModeStore } from "@/Layouts/Stores/usePersonalizationStore";
import { useOverlayStore } from "@/Layouts/Stores/useOverlayStore";
import type { AsideMaximizeState, AsideMaximizeStoreDefinition } from "@/Types/stores";

export const useAsideMaximizeStore: AsideMaximizeStoreDefinition = defineStore({
    id: 'useAsideMaximizeStore',
    state: (): AsideMaximizeState => ({
        show: false,
        layout: useLayoutModeStore(),
        overlay: useOverlayStore(),
    }),
    actions:
    {
        on(): void
        {
            if(this.layout.mode !== 'maximize') {
                return;
            }
            this.overlay.on();
            this.show = true;
        },
        off(): void
        {
            if(this.layout.mode !== 'maximize') {
                return;
            }
            this.overlay.off();
            this.show = false;
        }
    }
})