import type { RouteListStore } from "@/Types/stores";
import { computed, ref, type Ref } from "vue";
import { useRouteListStore } from "../Stores/useRouteListStore";
import { storeToRefs } from "pinia";
import type { ActivePageMenu } from "@/Types/composable";

export const useActivePageMenu: ActivePageMenu = computed(() =>{
    /**
     * Initiate useRouteListStore.
     * 
     * @type { RouteListStore }
     */
    const routeListStore: RouteListStore = useRouteListStore();
    const { list, name } = storeToRefs(routeListStore);
    
    const keyList: ReadonlyArray<string> = Object.keys(list.value);
    const proxyList: any = Object.create(list.value);
    const active: Ref<string|symbol> = ref('');

    keyList.forEach((main: string) => {
        if(proxyList[main].includes(name.value)) {
            active.value = name.value.toString();
        }
    });

    if(active.value === '') {
        return '';
    }

    const splitRouteName: ReadonlyArray<string> = active.value.toString().split('.');
    const firstRouteName: string = splitRouteName[0];

    return firstRouteName;
});