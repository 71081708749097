import type { HeaderMobileDropdownState, HeaderMobileDropdownStoreDefinition } from "@/Types/stores";
import { defineStore } from "pinia";

const DROPDOWN_TIMEOUT_DURATION: number = 700;
export const useHeaderMobileDropdownStore: HeaderMobileDropdownStoreDefinition = defineStore({
    id: 'useHeaderMobileDropdownStore',
    state: (): HeaderMobileDropdownState => ({
        active: false,
        classes: '',
        animation: false,
    }),
    actions:
    {
        /**
         * Show header mobile dropdown.
         * 
         * @param { string } page
         * @returns { void }
         */
        show(): void
        {
            if(this.animation === true) {
                return;
            }
            
            this.showAnimation();
        },

        /**
         * Hide header mobile dropdown.
         * 
         * @returns { void }
         */
        hide(): void
        {
            if(this.animation === true) {
                return;
            }
            
            this.hideAnimation();
        },

        /**
         * Show animation.
         * 
         * @returns { void }
         */
        showAnimation(): void
        {
            this.animation = true;
            this.active = true;
            this.classes = 'is-expand is-open';

            setTimeout(() => {
                this.animation = false;
                this.classes = this.classes.concat(" ", "is-show");
            }, DROPDOWN_TIMEOUT_DURATION);
        },

        /**
         * Hide animation.
         * 
         * @returns { void }
         */
        hideAnimation(): void
        {
            this.animation = true;
            this.classes = this.classes.replace('is-show', '');

            setTimeout(() => {
                this.classes = this.classes.replace('is-open', '');

                setTimeout(() => {
                    this.classes = '';
                    this.animation = false;
                    this.active = false;
                }, DROPDOWN_TIMEOUT_DURATION);
                
            }, DROPDOWN_TIMEOUT_DURATION);
        },
    },
});