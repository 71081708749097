<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";
// Icon Components
import CheckLgIcon from '@/Components/Icons/Bootstrap/CheckLgIcon.vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

const props = defineProps({
    active:
    {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Emits
*/

const emit = defineEmits(['handle-click-event']);

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Set active class.
 * 
 * @type { ComputedRef<string> }
 */
const setActiveClass: ComputedRef<string> = computed(() => {
    const { active } = props;

    return active ? 'is-active' : '';
});

/**
 * PART: Handle click event.
 * 
 * @returns { void }
 */
const handleClickEvent = (): void =>
{
    emit('handle-click-event');
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="dropdown__menu--item">
        <button 
            @click.prevent="handleClickEvent" 
            v-bind:class="[setActiveClass]"
            type="button" 
            class="dropdown__menu--link"
        >
            <div class="dropdown__menu--link__back">
                
            </div>
            <div class="dropdown__menu--link__front">
                <div class="dropdown__menu--link__main">
                    <div class="dropdown__menu--link__icon">
                        <slot name="icon" :variant="$props.active ? 'fill' : 'default'"></slot>
                    </div>
                    <div class="dropdown__menu--link__text">
                        <slot name="text"></slot>
                    </div>
                </div>
                <div v-if="$props.active" class="dropdown__menu--link__indicator">
                    <CheckLgIcon />
                </div>
            </div>
        </button>
    </li>
</template>