import type { LayoutModeStoreDefinition, PersonalizationThemeState, PersonalizationThemeStoreDefininiton } from "@/Types/stores";
import { defineStore } from "pinia";
import { useDeviceXL } from "../Composables/useBreakpoint";

export const usePersonalizationThemeStore: PersonalizationThemeStoreDefininiton = defineStore({
    id: 'usePersonalizationThemeStore',
    state: (): PersonalizationThemeState => ({
        colorScheme: window.theme.activeColorScheme,
        colorTheme: window.theme.activeColorTheme,
        module: window.theme,
    }),
    actions:
    {
        setDefaultColorScheme(): void
        {
            this.colorScheme = 'default';
            this.colorTheme = 'dark-default';
            this.module.switch('default', 'dark-default');
        },

        setDarkColorScheme(): void
        {
            this.colorScheme = 'dark';
            this.colorTheme = 'dark-default';
            this.module.switch('dark', 'dark-default');
        },

        setLightColorScheme(): void
        {
            this.colorScheme = 'light';
            this.colorTheme = 'light-default';
            this.module.switch('light', 'light-default');
        },
    },
});

export const useLayoutModeStore: LayoutModeStoreDefinition = defineStore({
    id: 'useLayoutModeStore',
    state: () => ({
        mode: window.mode.active,
        module: window.mode,
    }),
    actions:
    {
        /**
         * Set default layout mode.
         * 
         * @returns { void }
         */
        setDefault(): void
        {
            if(! useDeviceXL.value) {
                return;
            }

            this.mode = 'default';
            this.module.switch('default');
        },

        /**
         * Set minimize layout mode.
         * 
         * @returns { void }
         */
        setMinimize(): void
        {
            if(! useDeviceXL.value) {
                return;
            }

            this.mode = 'minimize';
            this.module.switch('minimize');
        },

        /**
         * Set maximize layout mode.
         * 
         * @returns { void }
         */
        setMaximize(): void
        {
            if(! useDeviceXL.value) {
                return;
            }
            
            this.mode = 'maximize';
            this.module.switch('maximize');
        },
    }
});