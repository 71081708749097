<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch, computed,type ComputedRef } from "vue";
// Vue Use
import { useMagicKeys } from "@vueuse/core";
import type { OnClickOutsideHandler } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
// Layout Components
import TheLogoIcon from "@/Layouts/Components/Logo/TheLogoIcon.vue";
import FloatBreadcrumb from "@/Layouts/Components/Float/FloatBreadcrumb.vue";
import FloatNavigatorActionButton from "@/Layouts/Components/Float/FloatNavigatorActionButton.vue";
import FloatAsideButton from "@/Layouts/Components/Float/FloatAsideButton.vue";
import TheFloatProfile from "@/Layouts/Components/Float/TheFloatProfile.vue";
// Layout Features
import TheFloatMainMenu from "@/Layouts/Features/TheMainMenu.vue";
import ThePersonalization from "@/Layouts/Features/ThePersonalization.vue";
import TheWorkspace from "@/Layouts/Features/TheWorkspace.vue";
// Layout Stores
import { useFloatNavigatorContainerStore } from "@/Layouts/Stores/useFloatStore";
import { useLayoutModeStore } from "@/Layouts/Stores/usePersonalizationStore";
// Icon Components
import HousesIcon from "@/Components/Icons/Bootstrap/HousesIcon.vue";
import XLGIcon from "@/Components/Icons/Bootstrap/XLGIcon.vue";
// Declaration Types
import type { FloatNavigatorContainerStore, LayoutModeStore } from "@/Types/stores";
import { storeToRefs } from "pinia";

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize the useLayoutModeStore.
 * 
 * @type { LayoutModeStore }
 */
const layoutModeStore: LayoutModeStore = useLayoutModeStore();
const { mode } = storeToRefs(layoutModeStore);

/**
 * PART: Initialize the useFloatNavigatorContainerStore.
 * 
 * @type { FloatNavigatorContainerStore }
 */
const floatNavigatorContainerStore: FloatNavigatorContainerStore = useFloatNavigatorContainerStore();

/**
 * PART: Float navigator class.
 * 
 * @type { ComputedRef<string> }
 */
const floatNavigatorClass: ComputedRef<string> = computed(() => floatNavigatorContainerStore.classes);

/**
 * PART: Handle close navigator.
 * 
 * @returns { void }
 */
const handleCloseNavigator = (): void =>
{
    floatNavigatorContainerStore.turnOff();
};

/**
 * PART: Handle on click outside.
 * 
 * @type { OnClickOutsideHandle }
 * @returns { void }
 */
const handleOnClickOutside: OnClickOutsideHandler = (): void =>
{
    handleCloseNavigator();
};

/**
 * PART: Initiate useMagicKeys()
 */
const { escape } = useMagicKeys();

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

// watch(m, (m_event) => {
//     if(m_event) {
//         if(active.value) {

//             if(name.value === 'main-menu')
//             {
//                 handleCloseNavigator();
//                 return;
//             }

//         } else {
//             floatNavigatorContainerStore.turnOn(
//                 'main-menu',
//                 'Main',
//                 'Menu'
//             );
//         }
//     }
// });

watch(escape, (escape_event) => {
    if(escape_event) {
        handleCloseNavigator();
    }
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <nav class="geardevs__layout geardevs__float gd-float">
        <div class="gd-float__wrapper">
            <div class="gd-float__container">
                <div class="gd-float__container--content gd-float__left">
                    <FloatAsideButton v-if="mode === 'maximize'" />
                </div>
                <div class="gd-float__container--content gd-float__center">

                    <div 
                        v-bind:class="[floatNavigatorClass]"
                        v-on-click-outside="handleOnClickOutside"
                        class="gd-float-navigator" 
                    >

                        <div class="gd-float-navigator__top">

                            <div class="gd-float-navigator__container">
                                <div class="gd-float-navigator__container--wrapper">
                                    <div id="gfncc" class="gd-float-navigator__container--content"></div>
                                </div>
                            </div>

                        </div>

                        <div class="gd-float-navigator__bottom">
                            
                            <div class="gd-float-navigator__header">
                                <div class="gd-float-navigator__header--wrapper">
                                    <div class="gd-float-navigator__header--wrapper__content">
                                        <a href="/" title="Geardevs" class="gd-float-navigator__brand">
                                            <TheLogoIcon />
                                        </a>
                                        <FloatBreadcrumb />
                                    </div>
                                    <div class="gd-float-navigator__header--wrapper__content">
                                        <TheFloatMainMenu>
                                            <template #float-main-menu>
                                                <slot name="float-main-menu"></slot>
                                            </template>
                                            <template #float-page-menu>
                                                <slot name="float-page-menu"></slot>
                                            </template>
                                        </TheFloatMainMenu>
                                        <button 
                                            @click.prevent="handleCloseNavigator" 
                                            type="button" 
                                            class="gd-float-navigator__button gd-float-navigator__button--close" 
                                            title="close"
                                        >
                                            <XLGIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="gd-float-navigator__main">
                                <div class="gd-float-navigator__main--wrapper">
                                    <div class="gd-float-navigator__main--content">
                                        <TheFloatProfile />
                                    </div>
                                    <div class="gd-float-navigator__main--content">
                                        <ul class="gd-float-navigator__menu">
                                            <FloatNavigatorActionButton title="Home">
                                                <HousesIcon  />
                                            </FloatNavigatorActionButton>
                                            <ThePersonalization />
                                            <TheWorkspace />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="gd-float__container--content gd-float__right">
                    
                </div>
            </div>
        </div>
    </nav>
</template>