<script setup lang="ts">

</script>
<template>
    <header class="error-exception__header">
        
    </header>
    <main class="error-exception__main">
        <div class="error-exception__main--wrapper">
            <div class="error-exception__main--container">
                <div class="error-exception__code">
                    <h1 class="error-exception__code--title">Oops, No Internet Connection</h1>
                </div>
                <div class="error-exception__text">
                    <p class="error-exception__text--message">
                        Please check your internet connectivity and try again.
                    </p>
                </div>
                <div class="error-exception__actions">
                    <button type="button" class="btn btn-default">
                        Try Again
                    </button>
                </div>
            </div>
        </div>
    </main>
</template>