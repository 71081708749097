<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch, computed, type ComputedRef } from "vue";
// Vue Use
import { useMagicKeys } from "@vueuse/core";
import type { OnClickOutsideHandler } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
// Layout Stores
import { useLayoutModeStore } from "@/Layouts/Stores/usePersonalizationStore";
import { useAsideMaximizeStore } from "@/Layouts/Stores/useAsideStore";
// Layout Components
import AsideButton from "@/Layouts/Components/Aside/AsideButton.vue";  
import TheBrand from "@/Layouts/Components/Logo/TheBrand.vue";
// Icon Components
import LayoutSidebarInsetIcon from "@/Components/Icons/Bootstrap/LayoutSidebarInsetIcon.vue";
import LayoutSidebarIcon from "@/Components/Icons/Bootstrap/LayoutSidebarIcon.vue";
import type { LayoutModeStore } from "@/Types/stores";
import { storeToRefs } from 'pinia';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initiate useMagicKeys()
 */
const { escape } = useMagicKeys();

/**
 * PART: Initialize layoutModeStore.
 * 
 * @type { LayoutModeStore }
 */
const layoutModeStore: LayoutModeStore = useLayoutModeStore();
const { mode } = storeToRefs(layoutModeStore);

/**
 * PART: Initialize asideMaximizeStore.
 * 
 */
const asideMaximizeStore = useAsideMaximizeStore();

/**
 * PART: Prefix class.
 * 
 * @type { string }
 */
const PREFIX: string = 'gd-aside';

/**
 * PART: Create class mode layout for aside element.
 * 
 * @type { ComputedRef<string> }
 */
const createClassModeLayout: ComputedRef<string> = computed(() => 
{
    return PREFIX.concat("__", mode.value);
});

/**
 * PART: Create class show aside when maximize layout mode is on.
 * 
 * @type { ComputedRef<string> }
 */
const createClassShowMaximize: ComputedRef<string> = computed(() =>
{
    const { show } = asideMaximizeStore;

    return show ? PREFIX.concat('__', 'maximize--show') : '';
});

/**
 * PART: Hide aside. (When layout is mode maximize)
 * 
 * @returns { void }
 */
const hideAside = (): void =>
{
    if(mode.value !== 'maximize') {
        return;
    }

    asideMaximizeStore.off();
};

/**
 * Handle event click outside of aside.
 * 
 * @type { OnClickOutsideHandler }
 * @returns { void }
 */
const handleClickOutside: OnClickOutsideHandler = (): void =>
{
    hideAside();
};

const handleSwitchMinimize = () =>
{
    console.log(mode.value);
    if(mode.value === 'default') {
        layoutModeStore.setMinimize();
    } else {
        layoutModeStore.setDefault();
    }
};

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

watch(escape, (escapeEvent: boolean) => 
{
    if(escapeEvent) {
        hideAside();
    }
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <aside 
        v-on-click-outside="handleClickOutside"
        v-bind:class="[PREFIX, createClassModeLayout, createClassShowMaximize]"
        class="geardevs__layout geardevs__aside"
    >
        <div class="gd-aside__back">

        </div>
        <div class="gd-aside__front">
            <div class="gd-aside__primary">
                <div class="gd-aside__primary--wrapper">
                    <ul class="gd-aside__menu">
                        <AsideButton :title="mode === `default` ? `Minimize` : `Normal`" @handle-click-event="handleSwitchMinimize">
                            <template #default>
                                <LayoutSidebarInsetIcon v-if="mode === 'minimize'" />
                                <LayoutSidebarIcon v-else />
                            </template>
                        </AsideButton>
                    </ul>
                    <ul class="gd-aside__menu">
                        <slot name="aside-menu-middle"></slot>
                    </ul>
                    <ul class="gd-aside__menu">
                        <slot name="aside-menu-bottom"></slot>
                    </ul>
                </div>
            </div>
            <div class="gd-aside__secondary">
                <div class="gd-aside__secondary--wrapper">
                    <div class="gd-aside__header">
                        <div class="gd-aside__header--wrapper">
                            <TheBrand />
                        </div>
                    </div>
                    <div class="gd-aside__body">
                        <div class="gd-aside__body--wrapper">
                            <slot name="aside-page-menu"></slot>
                        </div>
                    </div>
                    <div class="gd-aside__footer">
                        <div class="gd-aside__footer--wrapper">
                            <p class="gd-aside__footer--text">
                                Geardevs is trademark of <a href="https://github.com/geardevs07" target="_blank">Muhammad Leo</a>
                            </p>
                            <p class="gd-aside__footer--text">
                                Copyright &copy; 2022 - present
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</template>