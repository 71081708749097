<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";
import { RouterLink, type _RouterLinkI } from "vue-router";
// Layout Composables
import { useActiveMainMenu } from "@/Layouts/Composables/useActiveMainMenu";
// Components
import TheSpinner from "@/Components/Spinners/TheSpinner.vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    mounted:
    {
        type: Boolean,
        default: false,
    },
    to:
    {
        type: Object,
        required: true,
    },
    title:
    {
        type: String,
        required: true,
    },
    menu:
    {
        type: String,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * Replacing class 'router-link-exact-active';
 * 
 * @type { string }
 */
const ROUTER_LINK_EXACT_ACTIVE_CLASS: string = 'exact-active';

/**
 * PART: Get active menu.
 * 
 * @type { ComputedRef<string> }
 */
const activeMenu: ComputedRef<string> = useActiveMainMenu;

/**
 * PART: Determine active menu.
 * 
 * @type { ComputedRef<string> }
 */
const isExactActiveMenu: ComputedRef<string> = computed(() => {
    return props.menu === activeMenu.value
            ? ROUTER_LINK_EXACT_ACTIVE_CLASS
            : '';
});

/*
|----------------------------------------
** !SECTION
*/
</script>
<template>
    <li class="gd-aside__menu--item">
        <RouterLink 
            v-bind:to="$props.to"
            v-bind:title="$props.title"
            v-bind:data-aside-link-tooltip="$props.title"
            v-bind:class="[isExactActiveMenu]"
            v-slot="{ isExactActive, isActive }"
            :exact-active-class="isExactActiveMenu"
            active-class="active"
            
            class="gd-aside__link"
        >
            <div class="gd-aside__link--back"></div>
            <div class="gd-aside__link--front">
                <div v-if="isActive && !isExactActive" class="gd-aside__link--icon">
                    <TheSpinner size="sm" />
                </div>
                <div v-else class="gd-aside__link--icon">
                    <slot :variant="isExactActiveMenu === ROUTER_LINK_EXACT_ACTIVE_CLASS ? 'fill' : 'default' "></slot>
                </div>
            </div>
        </RouterLink>
    </li>
</template>