<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch } from "vue";
import { useRoute, type RouteLocationNormalizedLoaded } from "vue-router";
// Vue Use
import type { OnClickOutsideHandler } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
// Layout Components
import HeaderMobileDropdownButton from "@/Layouts/Components/Header/HeaderMobileDropdownButton.vue";
import HeaderMobileDropdownContent from "@/Layouts/Components/Header/HeaderMobileDropdownContent.vue";
import TheBrand from '@/Layouts/Components/Logo/TheBrand.vue';
// Layout Features
import ThePersonalizationThemeMobile from "@/Layouts/Features/ThePersonalizationThemeMobile.vue";
// Icon Components
import { useHeaderMobileDropdownStore } from '@/Layouts/Stores/useHeaderMobileStore';
import type { HeaderMobileDropdownStore } from '@/Types/stores';
import { computed, type ComputedRef } from 'vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize useRoute.
 * 
 * @type { RouteLocationNormalizedLoaded }
 */
const route: RouteLocationNormalizedLoaded = useRoute();

/**
 * PART: Initialize useHeaderMobileDropdownStore.
 * 
 * @type { HeaderMobileDropdownStore }
 */
const headerMobileDropdownStore: HeaderMobileDropdownStore = useHeaderMobileDropdownStore();

/**
 * PART: Get active state dropdown.
 * 
 * @type { ComputedRef<boolean> }
 */
const getActiveStateDropdown: ComputedRef<boolean> = computed(() => headerMobileDropdownStore.active);

/**
 * PART: Close header dropdown.
 * 
 * @returns { void }
 */
const closeHeaderDropdown = (): void =>
{
    if(getActiveStateDropdown.value === true) {
        headerMobileDropdownStore.hide();
    }
};

/**
 * PART: Handle on click outside.
 * 
 * @type { OnClickOutsideHandler }
 * @returns { void }
 */
const handleOnClickOutside: OnClickOutsideHandler = (): void => closeHeaderDropdown();

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

watch(route, () => {
    setTimeout(() => closeHeaderDropdown(), 1000);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <header v-on-click-outside="handleOnClickOutside" class="geardevs__layout geardevs__header-mobile gd-header-mobile">
        <div class="gd-header-mobile__wrapper">
            <div class="gd-header-mobile__container">
                <div class="gd-header-mobile__top">

                    <div class="gd-header-mobile__navigation">
                        <div class="gd-header-mobile__navigation--primary">
                            <TheBrand size="sm" />
                        </div>
                        <div class="gd-header-mobile__navigation--secondary">
                            <nav class="gd-header-mobile__nav">
                                <ul class="gd-header-mobile__nav--menu">
                                    <ThePersonalizationThemeMobile />
                                    <HeaderMobileDropdownButton />
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>
                <div class="gd-header-mobile__bottom">
                    <HeaderMobileDropdownContent>
                        <template #header-navigation>
                            <slot name="header-navigation"></slot>
                        </template>
                    </HeaderMobileDropdownContent>
                </div>
            </div>
        </div>
    </header>
</template>