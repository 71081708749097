<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Layout Components
import FloatPersonalizationComponent from '@/Layouts/Components/Float/FloatPersonalizationComponent.vue';
import FloatPersonalizationButton from "@/Layouts/Components/Float/FloatPersonalizationButton.vue";
// Layout Store
import { usePersonalizationThemeStore } from "@/Layouts/Stores/usePersonalizationStore";
// Icon Components
import CPUIcon from '@/Components/Icons/Bootstrap/CPUIcon.vue';
import MoonStarsIcon from '@/Components/Icons/Bootstrap/MoonStarsIcon.vue';
import SunIcon from '@/Components/Icons/Bootstrap/SunIcon.vue';
import { storeToRefs } from "pinia";
// Type declarations
import type { PersonalizationThemeStore } from "@/Types/stores";

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize usePersonalizationThemeStore.
 * 
 * @type { PersonalizationThemeStore }
 */
const themeStore: PersonalizationThemeStore = usePersonalizationThemeStore();

/**
 * PART: State of usePersonalizationThemeStore.
 */
const { colorScheme } = storeToRefs(themeStore);

/**
 * PART: Set default color scheme.
 * 
 * @returns { void }
 */
const setDefaultColorScheme = (): void =>
{
    themeStore.setDefaultColorScheme();
};

/**
 * PART: Set dark color scheme.
 * 
 * @returns { void } 
 */
const setDarkColorScheme = (): void =>
{
    themeStore.setDarkColorScheme();
};

/**
 * PART: Set light color scheme.
 * 
 * @returns { void }
 */
const setLightColorScheme = (): void =>
{
    themeStore.setLightColorScheme();
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <FloatPersonalizationComponent>
        <template #title>
            Theme
        </template>
        <template #default>
            <FloatPersonalizationButton 
                :mounted="true"
                :active="colorScheme === 'default'" 
                @handle-click-event="setDefaultColorScheme" 
                title="Default" 
            >
                <template #back-icon>
                    <CPUIcon />
                </template>
                <template #header-title>
                    #1
                </template>
                <template #icon="propsIcon">
                    <CPUIcon :variant="propsIcon.variant" />
                </template>
                <template #label>
                    Default
                </template>
            </FloatPersonalizationButton>
            <FloatPersonalizationButton 
                :mounted="true"
                :active="colorScheme === 'dark'" 
                @handle-click-event="setDarkColorScheme" 
                title="Dark" 
            >
                <template #back-icon>
                    <MoonStarsIcon />
                </template>
                <template #header-title>
                    #2
                </template>
                <template #icon="propsIcon">
                    <MoonStarsIcon :variant="propsIcon.variant" />
                </template>
                <template #label>
                    Dark
                </template>
            </FloatPersonalizationButton>
            <FloatPersonalizationButton 
                :mounted="true"
                :active="colorScheme === 'light'" 
                @handle-click-event="setLightColorScheme" 
                title="Light" 
            >
                <template #back-icon>
                    <SunIcon />
                </template>
                <template #header-title>
                    #3
                </template>
                <template #icon="propsIcon">
                    <SunIcon :variant="propsIcon.variant" />
                </template>
                <template #label>
                    Light
                </template>
            </FloatPersonalizationButton>
        </template>
    </FloatPersonalizationComponent>
</template>