import { computed, ref, type ComputedRef, type Ref } from "vue";
import { useRouteListStore } from "@/Layouts/Stores/useRouteListStore";
import type { RouteListStore } from "@/Types/stores";
import { storeToRefs } from "pinia";


export const useActiveMainMenu: ComputedRef<string> = computed(() => {
    /**
     * Initialize useRouteListStore.
     * 
     * @type { RouteListStore }
     */
    const routeListStore: RouteListStore = useRouteListStore();

    const { list, name } = storeToRefs(routeListStore);
    const keyList: ReadonlyArray<string> = Object.keys(list.value);
    const proxyList: any = Object.create(list.value);
    const active: Ref<string> = ref('');

    keyList.forEach((key: string) => {
        if(proxyList[key].includes(name.value)) {
            active.value = key;
        }
    });

    return active.value;
});