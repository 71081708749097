<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from 'vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    size:
    {
        type: String,
        default: 'base',
    },
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Prefix.
 * 
 * @type { string }
 */
const PREFIX: string = 'geardevs__brand';

/**
 * PART: Size allowed.
 * 
 * @type { ReadonlyArray<string> }
 */
const SIZE_ALLOWED: ReadonlyArray<string> = ['xs', 'sm', 'base', 'lg', 'xl'];

const setSize: ComputedRef<string|undefined> = computed(() => {
    const { size } = props;

    if(!SIZE_ALLOWED.includes(size)) {
        return;
    }

    return PREFIX.concat("--", size);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <a href="/" class="geardevs__anchor">
        <span
            v-bind:class="[PREFIX, setSize]"
            title="Geardevs" 
            data-brand-name="geardevs"
        >
            geardevs
        </span>
    </a>
</template>
<style lang="scss" scoped>
.geardevs__anchor {
    text-decoration: none;
}
</style>