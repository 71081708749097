<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from 'vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    mounted:
    {
        type: Boolean,
        default: true,
    },
    transparentBackground:
    {
        type: Boolean,
        default: false,
    },
    transparentBorder:
    {
        type: Boolean,
        default: true,
    },
    shadow:
    {
        type: Boolean,
        default: false,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Set transparent border class.
 * 
 * @type { ComputedRef<''|'section__ring'> }
 */
const setTransparentBorderClass: ComputedRef<''|'section__ring'> = computed(() =>
{
    return props.transparentBorder ? '' : 'section__ring';
});

/**
 * PART: Set shadow class.
 * 
 * @type { ComputedRef<''|'section__shadow'> }
 */
const setShadowClass: ComputedRef<''|'section__shadow'> = computed(() =>
{
    return props.shadow ? 'section__shadow' : '';
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <section class="section" v-bind:class="[setTransparentBorderClass, setShadowClass]">
        <div class="section__back">
            <div class="section__back--icon">
                <slot name="back-icon"></slot>
            </div>
            <div v-if="!$props.transparentBackground" class="section__back--background"></div>
            <div class="section__back--ring"></div>
        </div>
        <div class="section__front">
            <slot></slot>
        </div>
    </section>
</template>