<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Props
*/

defineProps({
    active:
    {
        type: Boolean,
        default: false,
    },
    title:
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Emit
*/

const emit = defineEmits(['handle-click-event']);

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="gd-aside__menu--item">
        <button 
            @click.prevent="emit('handle-click-event')"
            v-bind:title="$props.title"
            v-bind:data-aside-link-tooltip="$props.title"
            type="button" 
            class="gd-aside__link"
        >
            <div class="gd-aside__link--back"></div>
            <div class="gd-aside__link--front">
                <div class="gd-aside__link--icon">
                    <slot :variant="$props.active ? 'fill' : 'default' "></slot>
                </div>
            </div>
        </button>
    </li>
</template>