import type { OverlayState, OverlayStoreDefinition } from "@/Types/stores";
import { defineStore } from "pinia";

export const useOverlayStore: OverlayStoreDefinition = defineStore({
    id: 'useOverlayStore',
    state: (): OverlayState => ({
        show: false,
    }),
    actions:
    {
        on(): void
        {
            this.show = true;
        },
        off(): void
        {
            this.show = false;
        }
    }
});