import { useBreakpoints, breakpointsBootstrapV5 } from "@vueuse/core";
import { type Ref } from "vue";

/**
 * PART: Initiate useBreakpoints.
 */
const breakpoints = useBreakpoints(breakpointsBootstrapV5);

/**
 * Use device XL.
 * 
 * @type { Ref<boolean> }
 */
export const useDeviceXL: Ref<boolean> = breakpoints.greaterOrEqual("xl");