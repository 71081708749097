import { truncate } from "lodash-es";

export const useReplaceDashWithSpace = (text: string): string =>
{
    return text.replace(/-/g, " ");
};

export const useReplaceUnderscoreWithSpace = (text: string): string =>
{
    return text.replace(/_/g, " ");
};

export const useTruncate = (text: string, length: number = 12) => {
    return truncate(text, { length: length });
};