<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Components
import TheAvatar from "@/Components/Avatar/TheAvatar.vue";
import { useRouter } from "vue-router";

const router = useRouter();

function pushToBio()
{
    if(router.hasRoute('bio.introduction')) {
        router.push({ name: 'bio.introduction' });
    }
}

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button type="button" @click.prevent="pushToBio" title="Profile" class="gd-float-navigator__profile">
        <div class="gd-float-navigator__profile--avatar">
            <TheAvatar src="/images/avatar/avatar1.jpg" alt="Avatar" size="xs" :circle="true" />
        </div>
        <div class="gd-float-navigator__profile--info">
            <div class="gd-float-navigator__profile--name">
                Muhammad Leo <sup class="gd-float-navigator__profile--badge">dev</sup>
            </div>
            <div class="gd-float-navigator__profile--username">
                @aswah
            </div>
        </div>
    </button>
</template>