<template>
    <div class="gd-float-personalization__features">
        <div class="gd-float-personalization__header">
            <span class="gd-float-personalization__header--title">
                <slot name="title"></slot>
            </span>
        </div>
        <div class="gd-float-personalization__body">
            <slot></slot>
        </div>
    </div>
</template>