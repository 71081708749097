import type { 
    FloatMobileNavigationState,
    FloatMobileNavigationStoreDefinition,
    FloatNavigatorContainerState, 
    FloatNavigatorContainerStoreDefinition 
} from "@/Types/stores";
import { defineStore } from "pinia";
import { useBreadcrumbStore } from "./useBreadcrumbStore";

const TIMEOUT_DURATION: number = 700;

const useFloatNavigatorContainerStore: FloatNavigatorContainerStoreDefinition = defineStore({
    id: 'useFloatNavigatorContainerStore',
    state: (): FloatNavigatorContainerState => ({
        name: '',
        active: false,
        teleport: false,
        animation: false,
        classes: '',
        breadcrumb: useBreadcrumbStore(),
    }),
    actions: {
        turnOn(name: string, titleBreadcrumb: string, subtitleBreadcrumb: string): void
        {
            this.name = name;
            this.active = true;
            
            this.openNavigator();
            this.breadcrumb.set(titleBreadcrumb, subtitleBreadcrumb)
        },
        turnOff(): void
        {
            this.closeNavigator();
        },
        openNavigator(): void
        {
            if(this.animation === true){
                return;
            }

            this.animation = true;
            this.teleport = true;
            this.classes = 'is-expand is-start';

            setTimeout(() => {
                this.classes = this.classes.concat(" ", "is-open");

                setTimeout(() => {
                    this.animation = false;
                    this.classes = this.classes.concat(" ", "is-show");
                }, TIMEOUT_DURATION);
            }, TIMEOUT_DURATION)
        },
        closeNavigator(): void
        {
            if(this.animation === true) {
                return;
            }

            this.animation = true;
            this.classes = this.classes.replace("is-show", "");
            
            setTimeout(() => {
                this.classes = this.classes.replace("is-open", "");

                setTimeout(() => {
                    this.classes = this.classes.replace("is-expand", "");

                    setTimeout(() => {
                        this.name = "";
                        this.teleport = false;
                        this.animation = false;
                        this.active = false;
                        
                        this.classes = "";

                        this.breadcrumb.reset();
                    }, TIMEOUT_DURATION);
                }, TIMEOUT_DURATION);
            }, TIMEOUT_DURATION);
        },
    }
});

export const useFloatMobileNavigationStore: FloatMobileNavigationStoreDefinition = defineStore({
    id: 'useFloatMobileNavigationStore',
    state: (): FloatMobileNavigationState => ({
        name: '',
        active: false,
        animation: false,
        classes: '',
        breadcrumb: '',
        show: false,
    }),
    actions:
    {
        turnOn(name: string, breadcrumb: string): void
        {
            if(this.animation === true) {
                return;
            }

            this.active = true;
            this.name = name;
            this.breadcrumb = breadcrumb;
            this.open();
        },
        turnOff(): void
        {
            if(this.animation === true) {
                return;
            }

            this.close();
        },
        open(): void
        {
            this.animation = true;
            this.classes = 'is-expand is-start';

            setTimeout(() => {
                this.show = true;
                this.classes = this.classes.concat(" ", "is-open");
                setTimeout(() => {

                    this.classes = this.classes.concat(" ", "is-show");
                    this.animation = false;
                }, TIMEOUT_DURATION);
            }, TIMEOUT_DURATION);
        },
        close(): void
        {
            this.animation = true;
            this.classes = this.classes.replace("is-show", "");

            setTimeout(() => {
                this.classes = this.classes.replace("is-open", "");

                setTimeout(() => {
                    this.classes = this.classes.replace("is-expand", "");

                    setTimeout(() => {
                        this.name = "";
                        this.classes = "";
                        this.breadcrumb = "";
                        this.animation = false;
                        this.active = false;
                        this.show = false;
                        
                    }, TIMEOUT_DURATION);
                }, TIMEOUT_DURATION)
            }, TIMEOUT_DURATION)
        },
    },
});

export { useFloatNavigatorContainerStore };