<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue 
import { computed, type ComputedRef } from "vue";
// Layout Stores
import { useErrorExceptionStore } from '@/Layouts/Stores/useErrorException';
import type { ErrorExceptionStore } from '@/Types/stores';
import { storeToRefs } from 'pinia';
// Components
import TheButton from '@/Components/Buttons/TheButton.vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    indexRoute: {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize the useErrorExceptionStore.
 * 
 * @type { ErrorExceptionStore }
 */
const exception: ErrorExceptionStore = useErrorExceptionStore();
const { status, statusText, messages } = storeToRefs(exception);

/**
 * PART: Handle show error message.
 * 
 * @type { ComputedRef<string> }
 */
const handleShowErrorMessage: ComputedRef<string> = computed(() => 
{
    if(messages.value.message !== undefined && messages.value.message !== '') {
        return messages.value.message;
    }
    
    let message: string = '';
    switch(status.value) {
        case 404:
            message = 'Page what you looking for is not exists';
        break;
        default:
            message = 'We are facing an internal server error. Our experts are trying to fix the problem. Please try again later.';
        break;
    }
    
    return message;
});

/**
 * PART: Back to home page.
 * 
 * @returns { void }
 */
const backToHomePage = (): void =>
{
    window.location.href = props.indexRoute;
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <header class="error-exception__header">
        
    </header>
    <main class="error-exception__main">
        <div class="error-exception__main--wrapper">

            <div class="error-exception__main--container">
                <div class="error-exception__code">
                    <h1 class="error-exception__code--status" v-text="status"></h1>
                    <h2 class="error-exception__code--status-text" v-text="statusText"></h2>
                </div>
                <div class="error-exception__info">
                    <p class="error-exception__info--text" v-text="handleShowErrorMessage"></p>
                </div>
                <div class="error-exception__data">
                    <div v-for="(data, index) in messages" class="error-exception__data--wrapper">
                        <div v-if="index !== 'line' && index !== 'minutes' && index !== 'seconds'" class="error-exception__data--container">
                            <div class="error-exception__data--header">
                                <h3 class="error-exception__data--title" v-text="index"></h3>
                            </div>
                            <div class="error-exception__data--body">
                                <p v-if="(typeof data === 'string')" class="error-exception__data--text" v-text="data"></p>

                                <ul v-else class="error-exception__data--list">
                                    <li v-for="item in data" class="error-exception__data--list-item">

                                        <ul v-if="(typeof item === 'object')" class="error-exception__sub">
                                            <li v-for="(child, num) in item" class="error-exception__sub--item">
                                                <div class="error-exception__sub--title" v-text="num"></div>
                                                <div class="error-exception__sub--text" v-text="child"></div>
                                            </li>    
                                        </ul>

                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="error-exception__actions">
                    <TheButton @click.prevent="backToHomePage" type="button" title="Back to Home" :mounted="true" color="core">
                        <template #text>
                            Back to Home Page
                        </template>
                    </TheButton>
                </div>
            </div>
        </div>
    </main>
    <footer class="error exception__footer">

    </footer>
</template>