<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    active:
    {
        type: Boolean,
        default: false,
        required: true,
    },
    ariaLabel:
    {
        type: String,
        required: true,
    },
    ariaControls:
    {
        type: String,
        required: true,
    },
    variant: 
    {
        type: Number,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Emits
*/

const emit = defineEmits(['handle-click-event']);

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Hamburger prefix.
 * 
 * @type { string }
 */
const HAMBURGER_PREFIX: string = 'hamburger';

/**
 * PART: Set active class.
 * 
 * @type { ComputedRef<string> }
 */
const setActiveClass: ComputedRef<string> = computed(() =>
{
    return props.active ? 'active' : '';
});

/**
 * PART: Set variant class.
 * 
 * @type { ComputedRef<undefined|string> }
 */
const setVariantClass: ComputedRef<undefined|string> = computed(() => {
    if(props.variant === undefined) {
        return;
    }

    return HAMBURGER_PREFIX.concat('-', props.variant.toString());
});

/**
 * Handle click hamburger.
 * 
 * @returns { void }
 */
const handleClickHamburger = (): void =>
{
    emit("handle-click-event");
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button 
        @click.prevent="handleClickHamburger"
        v-bind:class="[setVariantClass, setActiveClass]"
        v-bind:aria-expanded="$props.active"
        v-bind:aria-label="$props.ariaLabel"
        v-bind:aria-controls="$props.ariaControls"
        type="button" 
        class="hamburger"
    >
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="hamburger__svg">
            <path class="hamburger__path hamburger__path--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
            <path class="hamburger__path hamburger__path--2" d="M0 50h70" />
            <path class="hamburger__path hamburger__path--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
        </svg>
    </button>
</template>