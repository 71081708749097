<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import type { MainStore } from '@/Types/stores';
import { ref, watchEffect, type Ref } from 'vue';
import { useMainStore } from './Stores/useMainStore';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize useMainStore.
 * 
 * @type { MainStore }
 */
const store: MainStore = useMainStore();

/**
 * PART: Main element.
 * 
 * @type { Ref<HTMLAreaElement|undefined> }
 */
const MAIN_ELEMENT: Ref<HTMLAreaElement|undefined> = ref();

watchEffect(() => {
    if(MAIN_ELEMENT.value !== undefined) {
        store.setMainOffsetTop(MAIN_ELEMENT.value?.offsetTop);
    }
})
/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <main ref="MAIN_ELEMENT" id="#main" class="geardevs__layout geardevs__main gd-main">
        <!-- <div class="gd-main__rays">
            <div class="gd-main__rays--child"></div>
        </div> -->
        <slot></slot>
    </main>
</template>