<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from "vue";
import { useRoute, type RouteLocationNormalizedLoaded, type RouteRecordName } from "vue-router";
// Layout Composables
import { titleBreadcrumb, subtitleBreadcrumb } from '@/Layouts/Composables/useBreadcrumb';
// Utils
import { useReplaceDashWithSpace } from "@/Utils/Strings";

/*
** !SECTION
|----------------------------------------
** SECTION: Import
*/

/**
 * Initialize useRoute.
 * 
 * @type { RouteLocationNormalizedLoaded }
 */
const route: RouteLocationNormalizedLoaded = useRoute();

/**
 * PART: Set page title.
 * 
 * @type { ComputedRef<undefined|string> }
 */
const setPageTitle: ComputedRef<undefined|string> = computed(() => 
{
    const routeName: RouteRecordName | null | undefined = route.name;

    if(routeName === null || routeName === undefined) {
        return;
    }

    const splitRouteName: ReadonlyArray<string> = routeName.toString().split('.');
    const secondRouteName = splitRouteName[1];

    return useReplaceDashWithSpace(
        secondRouteName === 'show' ? 'Read' : secondRouteName
    );
});

/*
|----------------------------------------
** !SECTION
*/
</script>
<template>
    <div class="gd-header__breadcrumb">
        <div id="ghbi" class="gd-header__breadcrumb--icon"></div>
        <div class="gd-header__breadcrumb--main">
            <span class="gd-header__breadcrumb--title" v-text="setPageTitle"></span>
            <ul class="gd-header__breadcrumb--content">
                <li class="gd-header__breadcrumb--item">
                    <span 
                        v-text="titleBreadcrumb.title"
                        v-bind:title="titleBreadcrumb.title"
                        class="gd-header__breadcrumb--text"
                    ></span>
                </li>
                <li class="gd-header__breadcrumb--item">
                    <span 
                        v-text="subtitleBreadcrumb.title"
                        v-bind:title="subtitleBreadcrumb.title"
                        class="gd-header__breadcrumb--text"
                    ></span>
                </li>
            </ul>
        </div>
    </div>
</template>