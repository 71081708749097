<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

import { computed, type ComputedRef } from "vue";
import { RouterLink, type _RouterLinkI } from "vue-router";
// Components
import TheSpinner from "@/Components/Spinners/TheSpinner.vue";
import { useActiveMainMenu } from "@/Layouts/Composables/useActiveMainMenu";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    to: 
    {
        type: Object,
        required: true,
    },
    title:
    {
        type: String,
        required: true,
    },
    serialNumber:
    {
        type: String,
        required: true,
    },
    menu:
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Active menu composables.
 * 
 * @type { ComputedRef<string> }
 */
const activeMainMenu: ComputedRef<string> = useActiveMainMenu

/**
 * Replacing class 'router-link-exact-active';
 * 
 * @type { string }
 */
const ROUTER_LINK_EXACT_ACTIVE_CLASS: string = 'exact-active';

/**
 * PART: Determine active menu.
 * 
 * @type { ComputedRef<string> }
 */
const isExactActiveMenu: ComputedRef<string> = computed(() => {
    return props.menu === activeMainMenu.value
            ? ROUTER_LINK_EXACT_ACTIVE_CLASS
            : '';
});
/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="gd-float-application-menu__list--item">
        <RouterLink 
            :to="$props.to"
            :exact-active-class="isExactActiveMenu"
            v-bind:title="$props.title"
            v-bind:class="[isExactActiveMenu]"
            v-slot="{ isActive, isExactActive}"
            active-class="active"
            class="gd-float-main-menu__link"
        >
            <div v-bind:data-float-main-menu-filler="$props.title" class="gd-float-main-menu__link--title">
                <span v-text="$props.title"></span>
                <sup v-if="isActive && !isExactActive">
                    <TheSpinner size="xs" />
                </sup>
                <sup v-else v-text="$props.serialNumber"></sup>
            </div>
            <div v-if="$slots['text']" class="gd-float-main-menu__link--text">
                <slot name="text"></slot>
            </div>
        </RouterLink>
    </li>
</template>