<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { ref, computed, type ComputedRef, type Ref } from "vue";
// Vue Use
import type { OnClickOutsideHandler } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    dropdownId:
    {
        type: String,
        required: true,
    },
    buttonClass:
    {
        type: String,
    },
    buttonTitle:
    {
        type: String,
        required: true,
    },
    align:
    {
        type: String,
        default: 'left',
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Dropdown content prefix.
 * 
 * @type { string }
 */
const DROPDOWN_CONTENT_PREFIX: string = 'dropdown__content';

/**
 * PART: Dropdown content allowed.
 * 
 * @type { ReadonlyArray<string> }
 */
const DROPDOWN_CONTENT_ALIGN_ALLOWED: ReadonlyArray<string> = ['left', 'center', 'right'];

/**
 * PART: Show the dropdown.
 * 
 * @type { Ref<boolean> }
 */
const show: Ref<boolean> = ref(false);

/**
 * PART: Dropdown button active.
 * 
 * @type { ComputedRef<string> }
 */
const setDropdownButtonActive: ComputedRef<string> = computed(() => 
{
    return show.value ? 'is-active' : '';
});
    
/**
 * PART: Dropdown content align.
 * 
 * @type { ComputedRef<undefined|string> }
 */
const setDropdownContentAlignClass: ComputedRef<undefined|string> = computed(() => {
    const { align } = props;

    if(!DROPDOWN_CONTENT_ALIGN_ALLOWED.includes(align)) {
        return;
    }

    return DROPDOWN_CONTENT_PREFIX.concat(`--${align}`)
});

/**
 * PART: Handle on click outside.
 * 
 * @type { OnClickOutsideHandler }
 * @returns { boolean }
 */
const handleOnClickOutside: OnClickOutsideHandler = (): boolean => show.value = false;

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <div class="dropdown" v-on-click-outside="handleOnClickOutside">
        <button 
            @click.prevent="show = !show"
            v-bind:class="[$props.buttonClass, setDropdownButtonActive]"
            v-bind:title="$props.buttonTitle"
            v-bind:aria-label="$props.buttonTitle"
            v-bind:aria-controls="$props.dropdownId"
            type="button" 
            class="dropdown__button"
        >
            <slot name="button"></slot>
        </button>
        <Transition name="dropdown__content--animation">
            <div v-if="show" v-bind:id="$props.dropdownId" v-bind:class="[setDropdownContentAlignClass]" class="dropdown__content">
                <div class="dropdown__content--landing">
                    <slot name="content"></slot>
                </div>
            </div>
        </Transition>
    </div>
</template>