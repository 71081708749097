<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/
import { computed, type ComputedRef } from "vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    size:
    {
        type: String,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Spinner Prefix
 * 
 * @type { string }
 */
const PREFIX: string = 'spinner-';

/**
 * PART: Setup spinner size.
 * 
 * @type { ComputedRef<string|undefined> }
 */
const setSpinnerSize: ComputedRef<string|undefined> = computed(() => {
    const { size } = props;
    const allowed: ReadonlyArray<string> = ['2xs', 'xs', 'sm', 'lg', 'xl', 'xxl'];

    if(size === undefined) {
        return;
    }

    if(! allowed.includes(size)) {
        return;
    }

    return PREFIX.concat(size);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <div 
        v-bind:class="[setSpinnerSize]"
        class="spinner-border"
    ></div>
</template>