<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { ref, type Ref, onMounted, computed, type ComputedRef } from "vue";
import { RouterLink } from "vue-router";
// Layout Composables
import { useActivePageMenu } from "@/Layouts/Composables/useActivePageMenu";
import { useDeviceXL } from "@/Layouts/Composables/useBreakpoint";
// Type Declaration
import type { ActivePageMenu } from "@/Types/composable";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    to: 
    {
        type: Object,
        required: true,
    },
    title:
    {
        type: String,
        required: true,
    },
    page:
    {
        type: String,
        required: true,
    },
    location:
    {
        type: String,
        default: 'float',
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Breadcrumb header location teleport id.
 * 
 * @type { string }
 */
const BREADCRUMB_ICON_TELEPORT_LOCATION_ID: string = '#ghbi';

/**
 * PART: Breadcrumb header location teleport status (exists).
 * 
 * @type {Ref<string> }
 */
const BREADCRUMB_ICON_TELEPORT_LOCATION_STATUS: Ref<boolean> = ref(false);

/**
 * PART: Active page menu.
 * 
 * @type { ActivePageMenu }
 */
const activePageMenu: ActivePageMenu = useActivePageMenu;

/**
 * PART: IS active page menu.
 * 
 * @type { ComputedRef<string> }
 */
const isActivePageMenu: ComputedRef<string> = computed(() => 
{
    return activePageMenu.value === props.page ? 'exact-active' : '';
});

/**
 * PART: Handle check location teleport.
 * 
 * @returns { void }
 */
const handleCheckLocationTeleport = (): void =>
{
    const breadcrumbElement: Element|null = document.querySelector(BREADCRUMB_ICON_TELEPORT_LOCATION_ID);
    
    if(breadcrumbElement !== null) {
        BREADCRUMB_ICON_TELEPORT_LOCATION_STATUS.value = true;
    }
}

/*
** !SECTION
|----------------------------------------
** SECTION: Lifecycle hooks
*/

onMounted(() => handleCheckLocationTeleport());

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li v-if="useDeviceXL" class="page-menu__list--item">
        <RouterLink 
            :to="$props.to" 
            v-bind:title="$props.title"
            v-bind:class="[isActivePageMenu]"
            active-class="active"
            exact-active-class="exact-active"
            class="page-menu__link"
        >
            <div class="page-menu__link--back"></div>
            <div class="page-menu__link--front">
                <div class="page-menu__link--icon">
                    <slot name="icon" :variant="isActivePageMenu === 'exact-active' ? 'fill' : 'default'"></slot>
                </div>
                <div class="page-menu__link--main">
                    <div class="page-menu__link--title">
                        <slot name="title"></slot> 
                    </div>
                    <div class="page-menu__link--description">
                        <slot name="description"></slot>
                    </div>
                </div>
            </div>
            <Teleport 
                v-if="BREADCRUMB_ICON_TELEPORT_LOCATION_STATUS && 
                    isActivePageMenu === 'exact-active' &&
                    $slots['breadcrumb-icon'] && 
                    $props.location === 'aside'" 
                :to="BREADCRUMB_ICON_TELEPORT_LOCATION_ID"
            >
                <slot name="breadcrumb-icon"></slot>
            </Teleport>
        </RouterLink>
    </li>
    <li v-else class="gd-float-mobile__menu--item">
        <RouterLink
            :to="$props.to"
            v-bind:title="$props.title"
            v-bind:class="[isActivePageMenu]"
            class="gd-float-mobile__anchor"
        >
            <div class="gd-float-mobile__anchor--back">

            </div>
            <div class="gd-float-mobile__anchor--front">
                <div class="gd-float-mobile__anchor--icon">
                    <div class="gd-float-mobile__anchor--svg">
                        <slot name="icon" :variant="isActivePageMenu === 'exact-active' ? 'fill' : 'default'"></slot>
                    </div>
                </div>
                <div class="gd-float-mobile__anchor--body">
                    <span class="gd-float-mobile__anchor--text">
                        <slot name="title"></slot>
                    </span>
                </div>
            </div>
        </RouterLink>
    </li>
</template>