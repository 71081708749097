<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { ref, watch, type Ref } from 'vue';
// Vue Use
import { useElementVisibility } from '@vueuse/core';
// Layout Stores
import { useMainStore } from '@/Layouts/Stores/useMainStore';
// Declaration types
import type { MainStore } from '@/Types/stores';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Get year.
 * 
 * @type { number }
 */
const year: number = new Date().getFullYear();

/**
 * PART: Initiate useMainStore.
 * 
 * @type { MainStore }
 */
const mainStore: MainStore = useMainStore();

/**
 * PART: Footer element.
 * 
 * @type { Ref<HTMLAreaElement|undefined> }
 */
const FOOTER_ELEMENT: Ref<HTMLAreaElement|undefined> = ref<HTMLAreaElement>();

/**
 * PART: Footer visibility.
 * 
 * @type { Ref<boolean> }
 */
const FOOTER_VISIBILITY: Ref<boolean> = useElementVisibility(FOOTER_ELEMENT);

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

watch(FOOTER_VISIBILITY, (visibility: boolean) => {
    mainStore.setFooterVisibility(visibility);
}, { immediate: true});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <footer ref="FOOTER_ELEMENT" class="geardevs__layout geardevs__footer gd-footer">
        <div class="gd-footer__wrapper">
            <div class="gd-footer__top">

            </div>
            <div class="gd-footer__bottom">
                <div class="gd-footer__bottom--content">
                    <ul class="gd-footer__menu">
                        <li class="gd-footer__menu--item">
                            <a href="#" class="gd-footer__menu--link">
                                contact
                            </a>
                        </li>
                        <li class="gd-footer__menu--item">
                            <a href="#" class="gd-footer__menu--link">
                                about
                            </a>
                        </li>
                        <li class="gd-footer__menu--item">
                            <a href="#" class="gd-footer__menu--link">
                                cookies
                            </a>
                        </li>
                        <li class="gd-footer__menu--item">
                            <a href="#" class="gd-footer__menu--link">
                                changelog
                            </a>
                        </li>
                        <li class="gd-footer__menu--item">
                            <a href="#" class="gd-footer__menu--link">
                                status
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="gd-footer__bottom--content">
                    <p class="gd-footer__copyright">
                        geardevs &copy; 2022 - {{ year }}. all right reserved
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>