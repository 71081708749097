<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch, ref, type Ref } from "vue";
import { useRoute, type RouteLocationNormalizedLoaded } from "vue-router";
// Vue Use
import type { OnClickOutsideHandler } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
// Layout Stores
import { useFloatMobileNavigationStore } from "@/Layouts/Stores/useFloatStore";
// Layout Components
import FloatBreadcrumb from "@/Layouts/Components/Float/FloatBreadcrumb.vue";
// Icon Components
import UICheckGridIcon from "@/Components/Icons/Bootstrap/UICheckGridIcon.vue";
// Components
import TheHamburger from "@/Components/Animation/TheHamburger.vue";
import { storeToRefs } from "pinia";
// Declaration type
import type { FloatMobileNavigationStore } from "@/Types/stores";

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize useRoute.
 * 
 * @type { RouteLocationNormalizedLoaded }
 */
const route: RouteLocationNormalizedLoaded = useRoute();

/**
 * PART: Initialized useFloatMobileNavigationStore.
 * 
 * @type { FloatMobileNavigationStore }
 */
const floatMobileNavigation: FloatMobileNavigationStore = useFloatMobileNavigationStore();
const { active, animation, classes, breadcrumb } = storeToRefs(floatMobileNavigation);

/**
 * PART: Main menu.
 * 
 * @type { Ref<boolean> }
 */
const MAIN_MENU: Ref<boolean> = ref(false);

/**
 * PART: Workspace menu.
 * 
 * @type { Ref<boolean> }
 */
const WORKSPACE_MENU: Ref<boolean> = ref(false);

/**
 * PART: Close navigation.
 * 
 * @returns { void }
 */
const closeNavigation = (): void =>
{
    if(animation.value === true) {
        return;
    }

    if(MAIN_MENU.value === true) {
        closeMainMenu();
    }

    if(WORKSPACE_MENU.value === true) {
        closeWorkspace();
    }

    floatMobileNavigation.turnOff();
};

/**
 * PART: Open navigation.
 * 
 * @param { string } name
 * @param { string } breadcrumb
 * @returns { void }
 */
const openNavigation = (name: string, breadcrumb: string): void => 
{
    floatMobileNavigation.turnOn(name, breadcrumb);
};

/**
 * PART: Close main menu.
 * 
 * @returns { void }
 */
const closeMainMenu = (): void =>
{
    MAIN_MENU.value = false;
    closeNavigation();
};

/**
 * PART: Open main menu.
 * 
 * @returns { void }
 */
const openMainMenu = (): void =>
{
    MAIN_MENU.value = true;
    WORKSPACE_MENU.value = false;
    openNavigation('main-menu', 'Main Menu');
};

/**
 * PART: Open workspace.
 * 
 * @returns { void }
 */
const openWorkspace = (): void =>
{
    if(animation.value === true) {
        return;
    }

    WORKSPACE_MENU.value = true;
    MAIN_MENU.value = false;

    openNavigation('workspace-menu', 'Workspace');
};

/**
 * PART: Close workspace.
 * 
 * @returns { void }
 */
const closeWorkspace = (): void =>
{
    WORKSPACE_MENU.value = false;
    closeNavigation();
};

/**
 * PART: Handle click outside.
 * 
 * @type { OnClickOutsideHandler }
 * @returns { void }
 */
const handleClickOutside: OnClickOutsideHandler = (): void => closeNavigation();

/**
 * PART: Handle hamburger.
 * 
 * @returns { void }
 */
const handleHamburger = (): void =>
{
    if(animation.value === true) {
        return;
    }

    if(MAIN_MENU.value === true) {
        closeMainMenu();
        return;
    }

    if(WORKSPACE_MENU.value === true) {
        closeWorkspace();
        return;
    }

    openMainMenu();
};

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

watch(route, (value: RouteLocationNormalizedLoaded) => {
    setTimeout(() => {
        closeNavigation();
    }, 400);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <nav class="geardevs__layout geardevs__float-mobile gd-float-mobile">
        <div class="gd-float-mobile__wrapper">
            <div class="gd-float-mobile__container">

                <div class="gd-float-mobile__content">

                    <div v-on-click-outside="handleClickOutside" v-bind:class="[classes]" class="gd-float-mobile__navigation">

                        <div class="gd-float-mobile__navigation--top">
                            <div id="gdf-mc" class="gd-float-mobile__menu--container">
                                <div class="gd-float-mobile__menu--wrapper">
                                    <div class="gd-float-mobile__menu--body">
                                        <nav class="gd-float-mobile__menu">
                                            <ul class="gd-float-mobile__menu--list">
                                                <slot name="float-navigation"></slot>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="gd-float-mobile__navigation--bottom">

                            <div class="gd-float-mobile__primary">
                                <div class="gd-float-mobile__primary--wrapper">
                                    <div class="gd-float-mobile__primary--content">
                                        <FloatBreadcrumb />
                                    </div>
                                    <div class="gd-float-mobile__primary--content">
                                        <button @click.prevent="openWorkspace" type="button" class="gd-float-mobile__workspace--button" title="Workspace" aria-label="Workspace">
                                            <UICheckGridIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="gd-float-mobile__secondary">
                                <div class="gd-float-mobile__secondary--wrapper">
                                    <div class="gd-float-mobile__menu--breadcrumb" v-text="breadcrumb"></div>
                                    <TheHamburger @handle-click-event="handleHamburger" :active="active" ariaLabel="Main Menu" ariaControls="gdf-mc" class="gd-float-mobile__menu--hamburger" />
                                </div>
                            </div>        

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </nav>
</template>