<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { ref, type Ref, onMounted } from "vue";
// Layout Stores.
import { useFloatNavigatorContainerStore } from '@/Layouts/Stores/useFloatStore';
import type { FloatNavigatorContainerStore } from '@/Types/stores';
import { storeToRefs } from 'pinia';
// Layout Components
import FloatNavigatorActionButton from '@/Layouts/Components/Float/FloatNavigatorActionButton.vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    name: 
    {
        type: String,
        required: true,
    },
    triggerTitle:
    {
        type: String,
        required: true,
    },
    titleBreadcrumb:
    {
        type: String,
        required: true,
    },
    subtitleBreadcrumb:
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialized useFloatNavigatorContainerStore.
 * 
 * @type { FloatNavigatorContainerStore }
 */
const floatNavigatorContainerStore: FloatNavigatorContainerStore = useFloatNavigatorContainerStore();

/**
 * PART: State of useFLoatNavigatorContainerStore.
 */
const { teleport, name } = storeToRefs(floatNavigatorContainerStore);

/**
 * PART: Teleport location
 * 
 * @var { string }
 */
const TELEPORT_LOCATION: string = '#gfncc';

/**
 * PART: Teleport status
 * 
 * @var { Ref<boolean> }
 */
const TELEPORT_LOCATION_STATUS: Ref<boolean> = ref(false);

/**
 * PART: Handle expand navigator.
 * 
 * @returns { void }
 */
const handleExpandNavigator = (): void =>
{
    floatNavigatorContainerStore.turnOn(
        props.name, 
        props.titleBreadcrumb, 
        props.subtitleBreadcrumb
    );
};

/**
 * PART: Handle check location teleport.
 * 
 * @returns { void }
 */
const handleCheckLocationTeleport = (): void =>
{
    const element: Element|null = document.querySelector(TELEPORT_LOCATION);

    if(element !== null) {
        TELEPORT_LOCATION_STATUS.value = true;
    }
};

/*
** !SECTION
|----------------------------------------
** SECTION: Lifecycle Hooks
*/

onMounted(() => handleCheckLocationTeleport());

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button 
        v-if="$slots['trigger-native']"
        @click.prevent="handleExpandNavigator"
        v-bind:title="$props.triggerTitle" 
        type="button" 
        class="gd-float-navigator__button gd-float-navigator__button--main-menu" 
        data-float-main-menu-button-tooltip="Main Menu"
    >
        <slot name="trigger-native"></slot>
    </button>
    <FloatNavigatorActionButton 
        v-if="$slots['trigger-action']" 
        v-bind:title="$props.triggerTitle"
        @handle-click-event="handleExpandNavigator"
    >
        <slot name="trigger-action"></slot> 
    </FloatNavigatorActionButton>
    <Teleport v-if="teleport && TELEPORT_LOCATION_STATUS && name === $props.name" :to="TELEPORT_LOCATION">
        <slot></slot>
    </Teleport>
</template>