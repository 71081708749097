import type { ViewComponent } from "@/Types/module";
import { createHead } from "@unhead/vue";
import { createPinia } from "pinia";
import { createApp, type App } from "vue";
import type { Router } from "vue-router";
import { inBrowser } from "./Shared";

function newApplication(Component: ViewComponent): App {
    return createApp(Component);
}

// function newRouter() {
//     // let initialPageLoad = inBrowser;
//     // let initialPath: string;    
// }

export async function createApplication(Component: ViewComponent, router: Router) {
    if(inBrowser === false) {
        throw new Error('^_^');
    }

    ;(globalThis as any).__GEARDEVS__ = true;

    // const router = newRouter();
    const app: App<Element> = newApplication(Component);
    
    app.use(router);
    app.use(createPinia());
    app.use(createHead());

    return { app, router };
}