<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watch } from 'vue';
// Layout stores
import { useOverlayStore } from "@/Layouts/Stores/useOverlayStore";
import { storeToRefs } from "pinia";

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize overlay store.
 */
const store = useOverlayStore();
const { show } = storeToRefs(store);

/**
 * PART: Handle window scroll.
 * 
 * @returns { void }
 */
const handleWindowScroll = (payload: boolean): void =>
{
    payload
        ? document.body.setAttribute('style', 'overflow:hidden')
        : document.body.removeAttribute('style');
};

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

watch(show, (value) => handleWindowScroll(value), { immediate: true });

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <Teleport v-if="show" to=".geardevs">
        <div class="gd-overlay"></div>
    </Teleport>
</template>