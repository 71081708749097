<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/
// Vue
import { computed, type ComputedRef, type PropType } from 'vue';
// Components
import TheSpinner from '@/Components/Spinners/TheSpinner.vue';
// Data
import { colorThemes } from "@/Data/Colors/ColorThemes";

/*
** !SECTION
|----------------------------------------
** SECTION: Declaration type
*/

type ButtonType = 'submit'|'reset'|'button'|undefined;

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    mounted:
    {
        type: Boolean,
        default: false,
    },
    type: {
        type: String as PropType<ButtonType>,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    variant: {
        type: String,
    },
    color: {
        type: String,
    },
    size: {
        type: String as PropType<'xs'|'sm'|'base'|'lg'|'xl'>,
        default: 'base',
    },
    disabled:
    {
        type: Boolean,
        default: false,
    },
    loading:
    {
        type: Boolean,
        default: false,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Button Prefix
 * 
 * @type { string }
 */
const BUTTON_PREFIX: string = 'btn';

/**
 * PART: Button prefix separator.
 * 
 * @type { Readonly<string> }
 */
const BUTTON_SEPARATOR_PREFIX: Readonly<string> = '__';

/**
 * PART: Button default color.
 * 
 * @type { string }
 */
const BUTTON_DEFAULT_COLOR: string = 'default';

/**
 * PART: Variant allowed.
 * 
 * @type { ReadonlyArray<string> }
 */
const variantAllowed: ReadonlyArray<string> = ['outline', 'soft', 'ghost', 'link'];

/**
 * PART: Set variant
 * 
 * @type { ComputedRef<string> }
 */
const setVariant: ComputedRef<string> = computed(() => 
{
    const { variant } = props;

    if(variant === undefined || variant === '') {
        return '';
    }

    if(!variantAllowed.includes(variant)) {
        return '';
    }

    return BUTTON_PREFIX.concat('__', variant);
});

/**
 * PART: Set color.
 * 
 * @type { ComputedRef<string> }
 */
const setColor: ComputedRef<string> = computed(() => {
    const { color } = props;
    const buttonDefault: string = BUTTON_PREFIX.concat('__', BUTTON_DEFAULT_COLOR);

    if(color === undefined || color === '') {
        return buttonDefault;
    }

    if(!colorThemes.includes(color)) {
        return buttonDefault;
    }

    return BUTTON_PREFIX.concat('__', color);
});

/**
 * PART: Set size.
 * 
 * @type { ComputedRef<string> }
 */
const setSize: ComputedRef<string> = computed(() =>
{
    const { size } = props;

    if(size === 'base') {
        return '';
    }

    return BUTTON_PREFIX.concat(BUTTON_SEPARATOR_PREFIX, size);
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button 
        v-if="$props.mounted"
        v-bind:type="$props.type"
        v-bind:title="$props.title" 
        v-bind:aria-label="$props.title"
        v-bind:class="[BUTTON_PREFIX, setVariant, setColor, setSize]"
        v-bind:disabled="$props.disabled"
    >
        <div class="btn__back">
            <div class="btn__back--background"></div>
            <div class="btn__back--background-hover"></div>
            <div class="btn__back--background-active"></div>
            <div class="btn__back--ring"></div>
            <div class="btn__back--ring-hover"></div>
            <div class="btn__back--ring-active"></div>
        </div>
        <div v-if="$props.loading" class="btn__front">
            <div class="btn__icon">
                <TheSpinner size="2xs" />
            </div>
            <div v-if="$slots['text']" class="btn__text" v-text="`Please wait`"></div>
        </div>
        <div v-else class="btn__front">
            <div v-if="$slots['icon']" class="btn__icon">
                <slot name="icon"></slot>
            </div>
            <div v-if="$slots['text']" class="btn__text">
                <slot name="text"></slot>
            </div>
        </div>
    </button>
</template>