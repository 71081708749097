import type { MainState, MainStoreDefinition } from '@/Types/stores';
import { defineStore } from 'pinia';

export const useMainStore: MainStoreDefinition = defineStore({
    id: 'useMainStore',
    state: (): MainState => ({
        mainOffsetTop: 0,
        mainHeaderOffsetHeight: 0,
        footerVisibility: false,
    }),
    actions: {
        /**
         * Set main offsetTop.
         * 
         * @param { number } offsetTop
         * @returns { void }
         */
        setMainOffsetTop(offsetTop: number): void {
            this.mainOffsetTop = offsetTop;
        },
        /**
         * Set main header offset height.
         * 
         * @param { number } offsetHeight
         * @returns { void }
         */
        setMainHeaderOffsetHeight(offsetHeight: number): void {
            this.mainHeaderOffsetHeight = offsetHeight;
        },
        /**
         * Set footer visibility.
         * 
         * @param { boolean } visibility
         * @returns { void }
         */
        setFooterVisibility(visibility: boolean): void
        {
            this.footerVisibility = visibility;
        }
    }
});