<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { computed, type ComputedRef } from 'vue';
import { useRoute, type RouteLocationNormalizedLoaded, type RouteRecordName } from 'vue-router';
// Layout Stores
import { useHeaderMobileDropdownStore } from '@/Layouts/Stores/useHeaderMobileStore';
// Layout Compasables
import { titleBreadcrumb, subtitleBreadcrumb } from '@/Layouts/Composables/useBreadcrumb';
// Declaration Type
import type { HeaderMobileDropdownStore } from '@/Types/stores';
// Utils
import { useReplaceDashWithSpace } from '@/Utils/Strings';
// Icon Component
import GithubIcon from '@/Components/Icons/Bootstrap/GithubIcon.vue';
import YoutubeIcon from '@/Components/Icons/Bootstrap/YoutubeIcon.vue';
import InstagramIcon from '@/Components/Icons/Bootstrap/InstagramIcon.vue';
import TwitterXIcon from '@/Components/Icons/Bootstrap/TwitterXIcon.vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * Initialize useRoute.
 * 
 * @type { RouteLocationNormalizedLoaded }
 */
const route: RouteLocationNormalizedLoaded = useRoute();

/**
 * PART: Set page title.
 * 
 * @type { ComputedRef<undefined|string> }
 */
const setPageTitle: ComputedRef<undefined|string> = computed(() => 
{
    const routeName: RouteRecordName | null | undefined = route.name;

    if(routeName === null || routeName === undefined) {
        return;
    }

    const splitRouteName: ReadonlyArray<string> = routeName.toString().split('.');
    const secondRouteName = splitRouteName[1];

    return useReplaceDashWithSpace(secondRouteName);
});

/**
 * PART: Initialize useHeaderMobileDropdownStore.
 * 
 * @type { HeaderMobileDropdownStore }
 */
const headerMobileDropdownStore: HeaderMobileDropdownStore = useHeaderMobileDropdownStore();

/**
 * PART: Get header mobile dropdown class.
 * 
 * @type { ComputedRef<string> }
 */
const getHeaderMobileDropdownClass: ComputedRef<string> = computed(() => headerMobileDropdownStore.classes);

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <div v-bind:class="[getHeaderMobileDropdownClass]" class="gd-header-mobile__dropdown">
        <div class="gd-header-mobile__dropdown--wrapper">
            <div class="gd-header-mobile__dropdown--content">
                <div class="gd-header-mobile__dropdown--main">

                    <div class="gd-header-mobile__dropdown--header">

                        <div class="gd-header-mobile__dropdown--header__content">                   
                            <ul class="gd-header-mobile__breadcrumb">
                                <li class="gd-header-mobile__breadcrumb--item">
                                    <span 
                                        v-text="titleBreadcrumb.text"
                                        v-bind:title="titleBreadcrumb.title"
                                        class="gd-header-mobile__breadcrumb--text"
                                    ></span>
                                </li>
                                <li class="gd-header-mobile__breadcrumb--item">
                                    <span 
                                        v-text="subtitleBreadcrumb.text"
                                        v-bind:title="subtitleBreadcrumb.title"
                                        class="gd-header-mobile__breadcrumb--text"
                                    ></span>
                                </li>
                            </ul>
                        </div>
                        <div class="gd-header-mobile__dropdown--header__content">
                            <span class="gd-header__mobile--dropdown--header__title" v-text="setPageTitle"></span>
                        </div>
                    </div>

                    <div class="gd-header-mobile__dropdown--body">
                        <nav class="gd-header-mobile__menu">
                            <ul id="gd__hmdc" class="gd-header-mobile__menu--list">
                                <slot name="header-navigation"></slot>
                            </ul>
                        </nav>
                    </div>
                    <div class="gd-header-mobile__dropdown--footer">
                        <ul class="gd-header-mobile__social-media">
                            <li class="gd-header-mobile__social-media--item">
                                <a href="#" target="_blank" class="gd-header-mobile__social-media--link" aria-label="Github" title="Github">
                                    <GithubIcon />
                                </a>
                            </li>
                            <li class="gd-header-mobile__social-media--item">
                                <a href="#" target="_blank" class="gd-header-mobile__social-media--link" aria-label="Youtube" title="Youtube">
                                    <YoutubeIcon />
                                </a>
                            </li>
                            <li class="gd-header-mobile__social-media--item">
                                <a href="#" target="_blank" class="gd-header-mobile__social-media--link" aria-label="Instagram" title="Instagram">
                                    <InstagramIcon />
                                </a>
                            </li>
                            <li class="gd-header-mobile__social-media--item">
                                <a href="#" target="_blank" class="gd-header-mobile__social-media--link" aria-label="Twitter X" title="Twitter X">
                                    <TwitterXIcon />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>