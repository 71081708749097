/**
 * Setup the routes.
 * 
 * @param { any } paths
 * @returns { ReadonlyArray<any> }
 */
export const setupRoutes = (paths: any): ReadonlyArray<any> => 
{
    const keyPaths: string[] = Object.keys(paths);
    const routes: any[] = [];

    keyPaths.forEach((menu: string) => {
        const keyPath: string[] = Object.keys(paths[menu]);

        keyPath.forEach((pathName: string) => {
            const createRoutePath = paths[menu][pathName]['path'];
            const createRouteComponent = paths[menu][pathName]['component'];
            const createRoute = Object.assign({}, {
                name: pathName,
                path: createRoutePath,
                component: createRouteComponent
            });

            routes.push(createRoute);
        })
    });

    return routes;
};

/**
 * Setup route list.
 * 
 * @param { Record<any, any> } paths
 * @returns { Record<any, any> }
 */
export const setupRouteList = (paths: Record<any, any>): Record<any, any> =>
{
    const keyPaths: string[] = Object.keys(paths);
    const routes: Record<any, any> = {};

    keyPaths.forEach((menu: string) => {
        routes[menu] = [];
        const keyPath: string[] = Object.keys(paths[menu]);

        keyPath.forEach((pathName: string) => {
            routes[menu].push(pathName);
        });
    });

    return routes;
};