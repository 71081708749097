<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

import { computed, type ComputedRef } from "vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    mounted:
    {
        type: Boolean,
        default: true,
    },
    src: 
    {
        type: String,
        required: true,
    },
    alt:
    {
        type: String,
        required: true,
    },
    size: 
    {
        type: String,
    },
    circle:
    {
        type: Boolean,
        default: false,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Avatar prefix.
 * 
 * @type { string }
 */
const AVATAR_PREFIX: string = 'avatar-';

/**
 * PART: Avatar size allowed.
 * 
 * @type { ReadonlyArray<string> }
 */
const AVATAR_SIZE_ALLOWED: ReadonlyArray<string> = ['sm', 'xs'];

/**
 * PART: Setup avatar size.
 * 
 * @type { ComputedRef<string|undefined> }
 */
const setAvatarSize: ComputedRef<string|undefined> = computed(() => {
    const { size } = props;

    if(size === undefined) {
        return;
    }

    if(!AVATAR_SIZE_ALLOWED.includes(size)) {
        return;
    }

    return AVATAR_PREFIX.concat(size);
});

/**
 * PART: Setup avatar circle.
 * 
 * @type { ComputedRef<string|undefined> }
 */
const setAvatarCircle: ComputedRef<string|undefined> = computed(() => {
    const { circle } = props;

    return circle === true ? 'avatar-circle' : '';
});

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <div v-if="$props.mounted" v-bind:class="[setAvatarSize, setAvatarCircle]" class="avatar">
        <figure class="avatar__figure">
            <img 
                v-bind:src="$props.src"
                v-bind:alt="$props.alt" 
                class="avatar__image" 
            />
        </figure>
    </div>
    <div v-else class="avatar placeholder placeholder__wave" v-bind:class="[setAvatarSize, setAvatarCircle]"></div>
</template>