<script setup lang="ts">
/*
TODO: Add Progress Bar ** FIXME
TODO: Fix Route Index to native url ** FIXME
|----------------------------------------
** SECTION: Import
*/

// Vue
import { watchEffect, watch, ref, computed, type Ref, type ComputedRef } from "vue";
import { useRoute, useRouter, RouterView, type RouteLocationNormalizedLoaded, type Router, type RouteRecordName, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router";
// VueUse
import { useNetwork } from "@vueuse/core";
//
import { useSeoMeta } from "@unhead/vue";
// Layout Part
import TheHeader from "@/Layouts/TheHeader.vue";
import TheHeaderMobile from "@/Layouts/TheHeaderMobile.vue";
import TheFloat from "@/Layouts/TheFloat.vue";
import TheFloatMobile from "@/Layouts/TheFloatMobile.vue";
import TheAside from "@/Layouts/TheAside.vue";
import TheMain from "@/Layouts/TheMain.vue";
import TheFooter from "@/Layouts/TheFooter.vue";
import TheSkipLink from '@/Layouts/Components/TheSkipLink.vue';
// Layout Stores
import { useRouteListStore } from "@/Layouts/Stores/useRouteListStore";
import { useErrorExceptionStore } from "@/Layouts/Stores/useErrorException";
import { useHeadStore } from '@/Layouts/Stores/useHeadStore';
// Layout Errors
import ErrorException from "@/Layouts/Errors/ErrorException.vue";
import OfflineException from "@/Layouts/Errors/OfflineException.vue";
// Layout Composable
import { useDeviceXL } from "@/Layouts/Composables/useBreakpoint";
// Layout Components
import TheOverlay from "@/Layouts/Components/TheOverlay.vue";
// Declaration types
import type { ErrorExceptionStore, RouteListStore } from "@/Types/stores";
import { storeToRefs } from "pinia";


/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

const props = defineProps({
    routeList:
    {
        type: Object,
        required: true,
    },
    float:
    {
        type: Boolean,
        default: true,
    },
    indexRoute:
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize the useRoute.
 * 
 * @type { RouteLocationNormalizedLoaded }
 */
const route: RouteLocationNormalizedLoaded = useRoute();

/**
 * PART: Initialize the useRouter.
 * 
 * @type { Router }
 */
const router: Router = useRouter();

/**
 * PART: Get status online
 */
const { isOnline } = useNetwork();

/**
 * PART: status online.
 * 
 * @type { Ref<boolean> }
 */
const online: Ref<boolean> = ref(true);

/**
 * PART: Initialize useErrorExceptionStore.
 * 
 * @type { ErrorExceptionStore }
 */
const exception: ErrorExceptionStore = useErrorExceptionStore();

/**
 * PART: Get error state.
 * 
 * @type { ComputedRef<boolean> }
 */
const errorState: ComputedRef<boolean> = computed(() => exception.error);

/**
 * PART: Initialize the useRouteListStore.
 * 
 * @type { RouteListStore }
 */
const routeListStore: RouteListStore = useRouteListStore();

/**
 * PART: Get list state from useRouteListStore().
 * 
 * @type { ComputedRef<Record<any, any>> }
 */
const listState: ComputedRef<Record<any, any>> = computed(() => routeListStore.list);

/**
 * PART: Handle initialize route list for useRouteListStore.
 * 
 * @returns { void }
 */
const handleInitializeRouteList = (): void => {
    const listLength = Object.keys(listState.value).length;

    if(listLength > 0) {
        return;
    }

    routeListStore.setListState(props.routeList);
};

/**
 * PART: Handle initialize route name for useRouteListStore.
 * 
 * @param { RouteLocationNormalizedLoaded } payload
 * @returns { void }
 */
const handleInitializeRouteName = (payload: RouteLocationNormalizedLoaded): void =>
{
    const routeName: RouteRecordName|null|undefined = payload.name;

    if(routeName === null || routeName === undefined) {
        return;
    }

    routeListStore.setNameState(routeName);
};

/*
** !SECTION
|----------------------------------------
** SECTION: Watch
*/

/**
 * PART: Handle initialize route list.
 */
watchEffect(() => handleInitializeRouteList());

/**
 * PART: Handle check index route.
 */
// watchEffect(() => handleCheckIndexRoute());

/**
 * PART: Handle initialize route name.
 */
watch(route, (value) => handleInitializeRouteName(value), { immediate: true });

/**
 * PART: Check network status.
 */
watch(isOnline, (value: boolean) => {
    if(value === true && online.value === false) {
        online.value = true;
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Router navigation guard
*/

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => 
{
    if(isOnline.value === false) {
        online.value = false;
    } else {
        next();
    }
});
/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <TheSkipLink />
    <ErrorException v-if="errorState === true" :index-route="$props.indexRoute" />
    <OfflineException v-if="online === false" />
    <TheOverlay />
    <TheHeader v-if="useDeviceXL && errorState === false && online === true">
        <template #header-navigation>
            <slot name="header-navigation"></slot>
        </template>
    </TheHeader>
    <TheHeaderMobile v-if="!useDeviceXL && errorState === false && online === true">
        <template #header-navigation>
            <slot name="header-navigation-mobile"></slot>
        </template>
    </TheHeaderMobile>
    <TheFloat v-if="useDeviceXL && errorState === false && online === true">
        <template #float-main-menu>
            <slot name="float-main-menu"></slot>
        </template>
        <template #float-page-menu>
            <slot name="float-page-menu"></slot>
        </template>
    </TheFloat>
    <TheFloatMobile v-if="!useDeviceXL && errorState === false && online === true">
        <template #float-navigation>
            <slot name="float-navigation"></slot>
        </template>
    </TheFloatMobile>
    <TheAside v-if="useDeviceXL && errorState === false && online === true">
        <template #aside-menu-middle>
            <slot name="aside-menu-middle"></slot>
        </template>
        <template #aside-menu-bottom>
            <slot name="aside-menu-bottom"></slot>
        </template>
        <template #aside-page-menu>
            <slot name="aside-page-menu"></slot>
        </template>
    </TheAside>
    <TheMain v-if="errorState === false && online === true">
        <RouterView />
    </TheMain>
    <TheFooter v-if="errorState === false && online === true"></TheFooter>
</template>