<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

import LayoutSidebarInsetIcon from '@/Components/Icons/Bootstrap/LayoutSidebarInsetIcon.vue';
import { useAsideMaximizeStore } from "@/Layouts/Stores/useAsideStore";
import { storeToRefs } from 'pinia';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * Initialize useAsideMaximizeStore.
 */
const asideMaximizeStore = useAsideMaximizeStore();
const { show } = storeToRefs(asideMaximizeStore);

/**
 * PART: Show aside.
 * 
 * @returns { void }
 */
const showAside = (): void => 
{
    asideMaximizeStore.on();
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button v-show="!show" type="button" @click.prevent="showAside" class="gd-float-aside__button" title="Show Sidebar">
        <LayoutSidebarInsetIcon />
    </button>
</template>