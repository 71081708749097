<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Components
import TheSection from "@/Components/Section/TheSection.vue";
import TheSectionHeader from "@/Components/Section/TheSectionHeader.vue";
import TheSectionBody from "@/Components/Section/TheSectionBody.vue";
// Icon Components
import CheckIcon from "@/Components/Icons/Bootstrap/CheckIcon.vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

defineProps({
    mounted:
    {
        type: Boolean,
        default: false,
    },
    title:
    {
        type: String,
        required: true,
    },
    active:
    {
        type: Boolean,
        default: false,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Emits
*/

const emit = defineEmits(['handle-click-event']);

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Handle click event.
 * 
 * @returns { void }
 */
const handleClickEvent = (): void => 
{
    emit('handle-click-event');
};

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <button 
        @click.prevent="handleClickEvent"
        v-bind:title="$props.title"
        v-bind:class="{
            active: $props.active
        }"
        type="button" 
        class="gd-float-personalization__button"
    >
        <TheSection>
            <template #back-icon>
                <slot name="back-icon"></slot>
            </template>
            <template #default>
                <TheSectionHeader :mounted="$props.mounted">
                    <template #header-title>
                        <slot name="header-title"></slot>
                    </template>
                </TheSectionHeader>
                <TheSectionBody>
                    <div class="gd-float-personalization__button--main">
                        <div class="gd-float-personalization__button--left">
                            <div class="gd-float-personalization__button--icon">
                                <slot name="icon" :variant="$props.active ? 'fill' : 'default'"></slot>
                            </div>
                            <div class="gd-float-personalization__button--label">
                                <slot name="label"></slot>
                            </div>
                        </div>
                        <div v-if="$props.active" class="gd-float-personalization__button--right">
                            <CheckIcon />
                        </div>
                    </div>
                </TheSectionBody>
            </template>
        </TheSection>
    </button>
</template>