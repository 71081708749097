// Vue
import { computed, ref, type Ref } from "vue";
// Pinia
import { storeToRefs } from "pinia";
// Layout Stores
import { useBreadcrumbStore } from "@/Layouts/Stores/useBreadcrumbStore";
import { useRouteListStore } from "@/Layouts/Stores/useRouteListStore";
// Declaration Types
import type { BreadcrumbStore, RouteListStore } from "@/Types/stores";
import type { Breadcrumb } from "@/Types/composable";
// Utils
import { useReplaceDashWithSpace, useTruncate } from "@/Utils/Strings";

/**
 * Title breadcrumb
 * 
 * @type { Breadcrumb }
 */
export const titleBreadcrumb: Breadcrumb = computed(() =>
{
    /**
     * Initialie useRouteListStore.
     * 
     * @type { RouteListStore }
     */
    const storeRouteList: RouteListStore = useRouteListStore();
    const { name, list } = storeToRefs(storeRouteList);

    const titleRef: Ref<string> = ref('');
    const keyList: ReadonlyArray<string> = Object.keys(list.value);
    const proxyList: any = Object.create(list.value);

    keyList.forEach((main: string) => {
        if(!proxyList[main].includes(name.value)) {
            return;
        }

        titleRef.value = useReplaceDashWithSpace(main);
    });

    return {
        text: useTruncate(titleRef.value, 8),
        title: titleRef.value
    }
});

/**
 * Subtitle breadcrumb
 * 
 * @type { Breadcrumb }
 */
export const subtitleBreadcrumb: Breadcrumb = computed(() =>
{
    /**
     * Initialie useRouteListStore.
     * 
     * @type { RouteListStore }
     */
    const storeRouteList: RouteListStore = useRouteListStore();
    const { name, list } = storeToRefs(storeRouteList);

    const subtitleRef: Ref<string> = ref('');
    const keyList: ReadonlyArray<string> = Object.keys(list.value);
    const proxyList: any = Object.create(list.value);

    keyList.forEach((main: string) => {
        const routeName: string|symbol = name.value;
        
        if(proxyList[main].includes(routeName)) {
            const splitRouteName: ReadonlyArray<string> = routeName.toString().split('.');
            const firstRouteName: string = splitRouteName[0];
            const secondRouteName: string = splitRouteName[1];

            const subtitleProxy = firstRouteName === main ? secondRouteName : firstRouteName;
            subtitleRef.value = useReplaceDashWithSpace(subtitleProxy);
        }
    });

    return {
        text: useTruncate(subtitleRef.value, 8),
        title: subtitleRef.value,
    }
});

export const floatTitleBreadcrumb: Breadcrumb = computed(() =>
{
    /**
     * Initialize useBreadcrumbStore.
     * 
     * @type { BreadcrumbStore }
     */
    const storeBreadcrumb: BreadcrumbStore = useBreadcrumbStore();
    const { title } = storeToRefs(storeBreadcrumb);

    if(title.value !== '') {
        
        return {
            text: title.value,
            title: title.value,
        }
    }

    return titleBreadcrumb.value;
});

export const floatSubtitleBreadcrumb: Breadcrumb = computed(() =>
{
    /**
     * Initialize useBreadcrumbStore.
     * 
     * @type { BreadcrumbStore }
     */
    const storeBreadcrumb: BreadcrumbStore = useBreadcrumbStore();
    const { subtitle } = storeToRefs(storeBreadcrumb);
    
    if(subtitle.value !== "") {
        return {
            text: subtitle.value,
            title: subtitle.value,
        }
    }

    return subtitleBreadcrumb.value;
});