<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Props
*/
import { ref, watch, type Ref } from 'vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';

const backToTop: Ref<undefined|HTMLSpanElement> = ref();
const route: RouteLocationNormalizedLoaded = useRoute();

function focusOnTargetAnchor({ target }: Event): void   
{
    const element: HTMLElement|null = document.getElementById(
        decodeURIComponent((target as HTMLAnchorElement).hash).slice(1)
    );

    if(element) {
        const removeTabIndex = () => {
            element.removeAttribute('tabindex');
            element.removeEventListener('blur', removeTabIndex);
        };

        element.setAttribute('tabindex', '-1');
        element.addEventListener('blur', removeTabIndex);
        element.focus();
        window.scrollTo(0, 0);
    }
}

watch(() => route.path, () => backToTop.value?.focus());

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <span ref="backToTop" tabindex="-1" />
    <a href="#main" class="gd__skip-link" @click="focusOnTargetAnchor">
        Skip to content
    </a>
</template>
<style lang="scss" scoped>
.gd__skip-link {
    top: 8px;
    left: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    color: var(--#{$prefix}theme-text-header);
    background-color: var(--#{$prefix}theme-secondary);
    position: fixed;
    opacity: 0;
}

.gd__skip-link:focus {
    height: auto;
    width: auto;
    clip: auto;
    clip-path: none;
    opacity: 1;
    z-index: 9999;
}

@media (min-width: 1280px) {
    .gd__skip-link {
        top: 14px;
        left: 16px;
    }
}
</style>