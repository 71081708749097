import type { RouteListStoreDefinition } from "@/Types/stores";
import type { RouteRecordName } from "vue-router";
import { defineStore } from "pinia";

export const useRouteListStore: RouteListStoreDefinition = defineStore({
    id: 'useRouteListStore',
    state: () => ({
        list: {},
        name: '' as RouteRecordName,
    }),
    actions: {
        setListState(routes: Record<any, any>): void
        {
            Object.assign(this.list, routes);
        },
        setNameState(name: RouteRecordName): void
        {
            this.name = name;
        }
    }
})