<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

/*
** !SECTION
|----------------------------------------
** SECTION: Props
*/

defineProps({
    title:
    {
        type: String,
        required: true,
    }
});

/*
** !SECTION
|----------------------------------------
** SECTION: Emits
*/

const emit = defineEmits(['handle-click-event']);

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <li class="gd-float-navigator__menu--item">
        <button 
            v-bind:title="$props.title"
            v-bind:data-float-navigator-action-tooltip="$props.title"
            @click.prevent="emit('handle-click-event')"
            type="button" 
            class="gd-float-navigator__action"
        >
            <div class="gd-float-navigator__action--back"></div>
            <div class="gd-float-navigator__action--front">
                <div class="gd-float-navigator__action--icon">
                    <slot></slot>
                </div>
            </div>
        </button>
    </li>
</template>